import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import router from './router'
import store from './store'
import App from './App.vue'

// Global Varaibles

//
global.jQuery = require('jquery');
var $ = global.jQuery;
window.$ = $;

//
window.api = "https://bashaer.brmja.com";
if(window.location.href.includes("localhost")){
  window.api = "http://127.0.0.1:3000";
}

if(window.location.href.includes("haj2")){
  window.api = 'https://shaj.brmja.com'
}


// Global Components
import './global-components'

// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

// Composition API
Vue.use(VueCompositionAPI)

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

window.alert = function(text, status='bg-secondary'){
  $("#open-alert").click()
  setTimeout(function(){
    $("#alert-msg").html(`<div class='alert text-white g text-center ${status == 100 ? 'bg-success' : (status == 200 ? 'bg-danger' : 'bg-secondary')}'>${text}</div>`)
  }, 100)
}

window.checkPer = function(per){
  var s = false;
  if(localStorage.getItem('user')){
    var user = JSON.parse(localStorage.getItem('user'));
    if(user.pers.includes("*") || user.pers.includes(per)){
      s = true;
    }
  }
  return s;
};

Vue.config.productionTip = false


new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
